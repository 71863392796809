// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import styles from "../../styles/footer.module.css"
import logoQ from "../../images/logos/quilmesLogo.svg"
import logoIg from "../../images/logos/logoIg.svg"
import logoFb from "../../images/logos/logoFb.svg"
import logoTw from "../../images/logos/logoTw.svg"
import logoYou from "../../images/logos/logoYou.svg"
import * as Scroll from "react-scroll"

let scroll = Scroll.animateScroll
const Footer = ({ bases }) => {
  function handleClick() {
    scroll.scrollToTop()
  }

  return (
    <footer className={`bottom  ${styles.footer_container}`}>
      <img
        onClick={handleClick}
        src={logoQ}
        alt="logo"
        className={styles.logoQ}
      />

      <div className={styles.footer_menu}>
        <div id={styles.footerOptions}>
          <a
            target="_blank"
            href={bases?.politica?.fileUrl}
            style={{ textDecoration: "none", color: "white" }}
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Footer",
                event_action: "Click",
                event_label: `${bases?.politica?.fileUrl}`,
                interaction: true,
                component_name: "",
                element_text: "",
              })
            }}
          >
            <p> POLÍTICA DE PRIVACIDAD</p>
          </a>
          <p> | </p>
          <a
            target="_blank"
            rel="noreferrer"
            href={bases?.bases?.fileUrl}
            style={{ textDecoration: "none", color: "white" }}
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Footer",
                event_action: "Click",
                event_label: `${bases?.bases?.fileUrl}`,
                interaction: true,
                component_name: "",
                element_text: "",
              })
            }}
          >
            <p> BASES Y CONDICIONES</p>
          </a>
          <p> | </p>
          <a
            target="_blank"
            href={bases?.tyc?.fileUrl}
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Footer",
                event_action: "Click",
                event_label: `${bases?.tyc?.fileUrl}`,
                interaction: true,
                component_name: "",
                element_text: "",
              })
            }}
          >
            <p>TÉRMINOS Y CONDICIONES</p>
          </a>
          <p id={styles.pHidden}> | </p>
          <a
            target="_blank"
            href="https://www.cerveceriaymalteriaquilmes.com/carrera/"
            style={{ textDecoration: "none", color: "white" }}
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Footer",
                event_action: "Click",
                event_label:
                  "https://www.cerveceriaymalteriaquilmes.com/carrera",
                interaction: true,
                component_name: "",
                element_text: "",
              })
            }}
          >
            <p>OPORTUNIDADES LABORALES</p>
          </a>
          <p> | </p>
          <a
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.tapintoyourbeer.com/agegate?destination=age_check.cfm"
            rel="noreferrer"
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Footer",
                event_action: "Click",
                event_label:
                  "https://www.tapintoyourbeer.com/agegate?destination=age_check.cfm",
                interaction: true,
                component_name: "",
                element_text: "",
              })
            }}
          >
            <p>INFORMACIÓN OFICIAL DE LA COMPAÑÍA</p>
          </a>
        </div>
        <span>
          BEBER CON MODERACIÓN.PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
          COMPARTA EL CONTENIDO CON MENORES. Quilmes®
        </span>
      </div>
      <div className={styles.socialContainer}>
        <a
          target="_blank"
          href="https://www.facebook.com/quilmescerveza/?ref=bookmarks"
          rel="noreferrer"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://www.facebook.com/quilmescerveza/?ref=bookmarks`,
              interaction: true,
              component_name: "boton_facebook",
              element_text: "Facebook",
            })
          }}
        >
          <img id={styles.facebook} src={logoFb} />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/Quilmes_Cerveza"
          rel="noreferrer"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://twitter.com/Quilmes_Cerveza`,
              interaction: true,
              component_name: "boton_twitter",
              element_text: "Twitter",
            })
          }}
        >
          <img className={styles.social} src={logoTw} />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/cervezaquilmes/?hl=es-la"
          rel="noreferrer"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://www.instagram.com/cervezaquilmes/?hl=es-la`,
              interaction: true,
              component_name: "boton_instagram",
              element_text: "Instagram",
            })
          }}
        >
          <img className={styles.social} src={logoIg} />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/user/Quilmes120Argentina"
          rel="noreferrer"
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Social",
              event_action: "Click",
              event_label: `https://www.youtube.com/user/Quilmes120Argentina`,
              interaction: true,
              component_name: "boton_youtube",
              element_text: "Youtube",
            })
          }}
        >
          <img className={styles.social} src={logoYou} />
        </a>
      </div>
      {/*  */}
    </footer>
  )
}
export default Footer
