import React from "react"
import HeaderTour from "./headerQuilmesTour"
import Footer from "./footerQuilmesTour"
import styles from "./layoutTour.module.css"
import "bootstrap/dist/css/bootstrap.min.css"
const LayoutTour = props => {
  // const [activeMenu, setActiveMenu] = useState(true)
  const reserva =
    props.location.pathname === "/lacasadequilmes/reserva" ||
    props.location.pathname === "/lacasadequilmes/reserva/step2" ||
    props.location.pathname === "/lacasadequilmes/thanks"

  const tourDescription =
    props.location.pathname === "/lacasadequilmes/tourdescription"

  return (
    <>
      <div className={styles.content}>
        <HeaderTour reserva={reserva} tourDescription={tourDescription} />
        {props.children}
        {!reserva || tourDescription ? <Footer bases={props.bases} /> : null}
      </div>
    </>
  )
}

export default LayoutTour
