import React from "react"
import { Link } from "gatsby"
import ArrowPrev from "../images/lacasadequilmes/tourCompleto/Frame23.png"
import { useMediaQuery } from "react-responsive"

function PrevArrowMobile(props) {
  const { className, style, onClick } = props
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  return (
    <img
      className={className}
      style={{
        ...style,
        display: "block",
        width: mobile ? "8%" : "auto",
        marginLeft: mobile ? 0 : "-30%",
      }}
      onClick={onClick}
      src={ArrowPrev}
    />
  )
}

export default PrevArrowMobile
