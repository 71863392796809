import React, { useState, useEffect } from "react"
// import { Link, navigate } from "gatsby"
import * as reachRouter from "@reach/router"
import { Link } from "react-scroll"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/home.module.css"
import BackgroundImage from "gatsby-background-image"
import SideMenu from "../sideMenu"
import { Select } from "antd"
import { DatePicker, Space } from "antd"
import moment from "moment"
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"
import downArrow from "../../images/logos/arrowQuilmesTour.svg"
import calendarIcon from "../../images/calendar.svg"
import "antd/dist/antd.css"
import "../../styles/quilmesTourStyles/index.css"
import teaser from "../../videos/1022_Teaser_Full_Online.mp4"

const { Option } = Select

const HomeTour = ({
  images,
  setPersonas,
  personas,
  fecha,
  tour,
  tourOptions,
  dateOptionsOfCurrentTour,
  onDateChange,
  onTourChange,
  availability,
  counterUp,
  counterDown,
}) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [activeMenu, setActiveMenu] = useState(true)
  const [isActive, setIsActive] = useState(true)
  const [disabledButton, setDisabledButton] = useState(true)

  // console.log("TOUR OPTIONSS", tourOptions)
  // console.log("toour", tour)

  useEffect(() => {
    if (disabledButton && tour.name && fecha) setDisabledButton(false)
    if (!disabledButton || !tour.name || !fecha) setDisabledButton(true)
  }, [tour, fecha])

  function disabledDate(current) {
    if (dateOptionsOfCurrentTour) {
      return (
        current < moment().endOf("day") ||
        !dateOptionsOfCurrentTour.some(
          date => date.fecha === current.format("YYYY-MM-DD")
        )
      )
    }
  }

  return (
    <>
      {!mobile ? (
        <BackgroundImage
          fluid={images["fotos_quilmes_002"].childImageSharp.fluid}
          className={styles.top1}
        >
          <div className={styles.top1}>
            <div style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}></div>
            {/* <video src={teaser} loop muted autoPlay className={styles.backgroundVideo} /> */}
            <div className={styles.mainContainer}>
              <div className={styles.textContainer}>
                <h1 className={styles.home130text}>
                  ¡Hola!<br/><br/>

                  Nos estamos re-acomodando para mejorar tu experiencia.<br/><br/>

                  ¡Nos reencontramos pronto!<br/><br/>

                  Seguinos en @cervezaquilmes y @parquedelacerveceria para enterarte todas la novedades.
                </h1>
                {/* <div className={styles.rectangle1}></div>
              <div className={styles.rectangle2}></div> */}
              </div>
              {/*<SideMenu*/}
              {/*  activeMenu={activeMenu}*/}
              {/*  setActiveMenu={setActiveMenu}*/}
              {/*></SideMenu>*/}
              {/*<div className={styles.invitationMobileContainer}>*/}
              {/*  <div className={styles.cuandoVenisContainer}>*/}
              {/*    <div className={styles.cuandoVenisDiv}>*/}
              {/*      <h1 className={styles.cuandoVenisTexto}>¿Cuándo venis?</h1>*/}
              {/*    </div>*/}
              {/*    <div className={styles.cuandoVenisSelectContainer}>*/}
              {/*      <Select*/}
              {/*        placeholder="Elegí el tour"*/}
              {/*        onChange={onTourChange}*/}
              {/*        style={{*/}
              {/*          width: "100%",*/}
              {/*          height: "50px",*/}
              {/*          display: "flex",*/}
              {/*          alignItems: "center",*/}
              {/*          fontSize:"18px"*/}
              {/*        }}*/}
              {/*        size="large"*/}
              {/*        bordered={false}*/}
              {/*      >*/}
              {/*        {tourOptions.map(tour => (*/}
              {/*          <Option*/}
              {/*            value={JSON.stringify({*/}
              {/*              name: tour.tourdescrip,*/}
              {/*              id: tour.idTour,*/}
              {/*              precio: tour.precio,*/}
              {/*              // paymentLink1: tour.paymentLink1,*/}
              {/*              // paymentLink2: tour.paymentLink2,*/}
              {/*              // paymentLink3: tour.paymentLink3,*/}
              {/*              // paymentLink4: tour.paymentLink4,*/}
              {/*              // paymentLink5: tour.paymentLink5,*/}
              {/*            })}*/}
              {/*          >*/}
              {/*            {tour.tourdescrip}*/}
              {/*          </Option>*/}
              {/*        ))}*/}
              {/*      </Select>*/}
              {/*      <Space direction="vertical" style={{ width: "100%" }}>*/}
              {/*        <DatePicker*/}
              {/*          value={fecha ? moment(fecha) : null}*/}
              {/*          disabledDate={disabledDate}*/}
              {/*          disabled={!dateOptionsOfCurrentTour}*/}
              {/*          onChange={onDateChange}*/}
              {/*          placeholder="Elegí la fecha"*/}
              {/*          style={{*/}
              {/*            width: "100%",*/}
              {/*            height: "auto",*/}
              {/*            display: "flex",*/}
              {/*            border:"none",*/}
              {/*            fontSize:"18px",*/}
              {/*            alignItems: "center",*/}
              {/*            marginTop:"5px",*/}
              {/*            gap:"0px",*/}
              {/*          }}*/}
              {/*          className={styles.datepicker}*/}
              {/*          size="large"*/}
              {/*          suffixIcon={*/}
              {/*            <img*/}
              {/*              src={calendarIcon}*/}
              {/*              style={{*/}
              {/*                height: "18px",*/}
              {/*              }}*/}
              {/*            ></img>*/}
              {/*          }*/}
              {/*        />*/}
              {/*      </Space>*/}
              {/*      <div className={styles.personasSelect}>*/}
              {/*        <MinusCircleOutlined onClick={counterDown} />*/}
              {/*        <h1 className={styles.pText}>*/}
              {/*          {personas} PERSONA{personas > 1 && "S"}*/}
              {/*        </h1>*/}
              {/*        <PlusCircleOutlined onClick={counterUp} />*/}
              {/*      </div>*/}
              {/*      {disabledButton ? (*/}
              {/*        <button className={styles.reservaDeshabilitada}>*/}
              {/*          <h1 className={styles.botonReserva}>RESERVAR</h1>*/}
              {/*        </button>*/}
              {/*      ) : (*/}
              {/*        <button className={styles.reserva}>*/}
              {/*          /!*<button className={styles.reservaDeshabilitada}>*!/*/}
              {/*          <reachRouter.Link*/}
              {/*            to="/lacasadequilmes/reserva"*/}
              {/*            onClick={() => {*/}
              {/*              window.dataLayer.push({*/}
              {/*                event: "GAEvent",*/}
              {/*                event_category: "Reservation",*/}
              {/*                event_action: `Submit`,*/}
              {/*                event_label: `${tour.name}`,*/}
              {/*                interaction: true,*/}
              {/*                component_name: "boton_reservar",*/}
              {/*                element_text: "Reservar",*/}
              {/*              })*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            <h1 className={styles.botonReserva}>RESERVAR</h1>*/}
              {/*          </reachRouter.Link>*/}
              {/*        </button>*/}
              {/*      )}*/}
              {/*    </div>*/}
              {/*    /!* <h1 className={styles.cuandoVenisTexto2}>*/}
              {/*      Tour disponible para mayores de 18 años únicamente.*/}
              {/*    </h1> *!/*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </BackgroundImage>
      ) : (
        <>
          <BackgroundImage
            fluid={images["bg"].childImageSharp.fluid}
            className={styles.top1}
          >
            <BackgroundImage

              fluid={images["fotos_quilmes_002"].childImageSharp.fluid}
              className={styles.beerContainer}
            >
              <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}></div>
              <div className={styles.textContainer}>
                <h1 className={styles.home130text}>
                  ¡Hola!<br/><br/>

                  Nos estamos re-acomodando para mejorar tu experiencia.<br/><br/>

                  ¡Nos reencontramos pronto!<br/><br/>

                  Seguinos en @cervezaquilmes y @parquedelacerveceria para enterarte todas la novedades.
                </h1>
                {/*<SideMenu*/}
                {/*  activeMenu={activeMenu}*/}
                {/*  setActiveMenu={setActiveMenu}*/}
                {/*></SideMenu>*/}
                {/* <div className={styles.rectangle1}></div> */}
              </div>
            </BackgroundImage>

          </BackgroundImage>
        </>
      )}
    </>
  )
}

// <div className={styles.invitationMobileContainer}>
//   <div className={styles.cuandoVenisContainer}>
//     <h1 className={styles.cuandoVenisTexto}>¿Cuándo venis?</h1>
//     <div className={styles.cuandoVenisSelectContainer}>
//       <Select
//         placeholder="TOUR"
//         onChange={onTourChange}
//         style={{
//           width: "100%",
//           height: "60px",
//           display: "flex",
//           alignItems: "center",
//         }}
//         size="large"
//         bordered={false}
//       >
//         {tourOptions.map(tour => (
//           <Option
//             value={JSON.stringify({
//               name: tour.tourdescrip,
//               id: tour.idTour,
//               precio: tour.precio,
//               // paymentLink1: tour.paymentLink1,
//               // paymentLink2: tour.paymentLink2,
//               // paymentLink3: tour.paymentLink3,
//               // paymentLink4: tour.paymentLink4,
//               // paymentLink5: tour.paymentLink5,
//             })}
//           >
//             {tour.tourdescrip}
//           </Option>
//         ))}
//       </Select>
//       <Space direction="vertical" style={{ width: "100%" }}>
//         <DatePicker
//           value={fecha ? moment(fecha) : null}
//           disabledDate={disabledDate}
//           disabled={!dateOptionsOfCurrentTour}
//           placeholder="FECHA"
//           onChange={onDateChange}
//           style={{
//             width: "100%",
//             height: "60px",
//             display: "flex",
//             alignItems: "center",
//           }}
//           size="large"
//           suffixIcon={
//             <img
//               src={calendarIcon}
//               style={{
//                 height: "18px",
//               }}
//             ></img>
//           }
//         />
//       </Space>
//       <div className={styles.personasSelect}>
//         <MinusCircleOutlined onClick={counterDown} />
//         <h1 className={styles.pText}>
//           {personas} PERSONA{personas > 1 && "S"}
//         </h1>
//         <PlusCircleOutlined onClick={counterUp} />
//       </div>
//       {disabledButton ? (
//         <button className={styles.reservaDeshabilitada}>
//           <h1 className={styles.botonReserva}>RESERVAR</h1>
//         </button>
//       ) : (
//         /*<button className={styles.reservaDeshabilitada}>*/
//         <button className={styles.reserva}>
//           <reachRouter.Link
//             to="/lacasadequilmes/reserva"
//             onClick={() => {
//               window.dataLayer.push({
//                 event: "GAEvent",
//                 event_category: "Reservation",
//                 event_action: `Submit`,
//                 event_label: `${tour.name}`,
//                 interaction: true,
//                 component_name: "boton_reservar",
//                 element_text: "Reservar",
//               })
//             }}
//           >
//             <h1 className={styles.botonReserva}>RESERVAR</h1>
//           </reachRouter.Link>
//         </button>
//       )}
//     </div>
//     {/* <h1 className={styles.cuandoVenisTexto2}>
//                   Tour disponible para mayores de 18 años únicamente.
//                 </h1> */}
//     <h1 className={styles.cuandoVenisTexto3}>
//       Explora las cuatro propuestas
//     </h1>
//     <div className={isActive ? styles.arrowMain : styles.hidden}>
//       <Link to="tour" smooth={true}>
//         <img
//           className={styles.arrowImage}
//           alt="flecha para ir abajo"
//           src={downArrow}
//         />{" "}
//       </Link>
//     </div>
//   </div>
// </div>

export default HomeTour
