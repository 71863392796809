import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import logoMenu from "../../images/logos/logoMenu.svg"
import styles from "../../styles/quilmesTourStyles/headerQuilmesTour.module.css"
import SideMenu from "../sideMenu"
import logoTour from "../../images/logos/LCDQ_blanco.png"
import logoReserva from "../../images/logos/Vector.svg"

const HeaderTour = ({ reserva, tourDescription }) => {
  const [activeMenu, setActiveMenu] = useState(true)

  // useEffect(() => {
  //   console.log("header boolean", reserva)
  // })

  return (
    <>
      {!tourDescription ? (
        <>
          <SideMenu
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          ></SideMenu>
          <div className={reserva ? styles.tourHeaderMobile : styles.tourHeader}>
            <div
              className={
                reserva ? styles.tourHeaderContent : styles.tourHeaderContentHome
              }
            >
              <Link to={reserva ? "/lacasadequilmes" : `/`}>
                <img
                  // style={{ display: "none" }}
                  className={reserva ? styles.logoTourMobile : styles.logoTour}
                  src={reserva ? logoReserva : logoTour}
                  alt="logo130"
                />
              </Link>
              <div
                onClick={() => {
                  setActiveMenu(!activeMenu)
                }}
                className={styles.menuHeader}
              >
                <span>MENÚ</span>
                <img
                  className={styles.menuIcon}
                  src={logoMenu}
                  alt="menuIcon"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default HeaderTour
